import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import HeaderMain from '../header-main/HeaderMain'
import MultipleChoice from '../multipleChoice/MultipleChoice'
import results from 'images/results.svg'

import './Uitslag.scss'

class Uitslag extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      allPages: [],
      currentPage: {},
      showBreadcrumb: false
    }
  }

  componentWillMount () {
    this.props.requestUitslagPages()
  }

  componentWillReceiveProps (newProps) {
    let pages = newProps.pages.toJS()
    if (this.props.pages !== pages && pages) {
      pages = pages.pages
      pages = _.sortBy(pages, ['position'])
      pages.map(page => {
        if ('children' in page) {
          if (page.children.length > 0) {
            page.children = _.sortBy(page.children, ['position'])
            page.children.map(childPage => {
              if ('children' in childPage) {
                if (childPage.children.length > 0) {
                  childPage.children = _.sortBy(childPage.children, [
                    'position'
                  ])
                  childPage.children.map(childChildPage => {
                    if ('children' in childChildPage) {
                      if (childChildPage.children.length > 0) {
                        childChildPage.children = _.sortBy(
                          childChildPage.children,
                          ['position']
                        )
                      }
                    }
                  })
                }
              }
              return childPage
            })
          }
        }
        return page
      })
      this.setState({
        allPages: pages,
        currentPage: pages[0]
      })
    }
  }

  openPage (i) {
    const { allPages } = this.state
    this.setState({
      currentPage: allPages[0].children[i],
      showBreadcrumb: true
    })
  }

  openMainPage () {
    const { allPages } = this.state
    this.setState({
      currentPage: allPages[0],
      showBreadcrumb: false
    })
  }

  render () {
    return (
      <div className='uitslag'>
        <HeaderMain title={'Uitslag'} image={results} />
        {this.state.allPages.length > 0 ? (
          <div className='content-uitslag'>
            <div className='container absolute-container'>
              <MultipleChoice pages={this.state.allPages[0]} />
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    pages: state.get('uitslagPages')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestUitslagPages: () => dispatch({ type: 'UITSLAG_PAGES_REQUESTED' })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Uitslag)
