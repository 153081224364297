import { List, fromJS } from 'immutable'

export default (state = List(), action) => {
  switch (action.type) {
    case 'APP_PAGES_FAILED':
      return fromJS(action.message)
    default:
      return null
  }
}
