import React from 'react'
import { useTransition, animated } from 'react-spring'

import styles from './Choice.module.css'

export default function Choice (Props) {
  const items = Props.choices
  const transitions = useTransition(items, item => item.id, {
    from: {
      transform: `translate3d(${!Props.prev ? '-' : ''}400px,0,0)`,
      opacity: 0
    },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    leave: {
      transform: `translate3d(${Props.prev ? '-' : ''}400px,0,0)`,
      opacity: 0
    },
    native: true,
    order: ['leave', 'enter', 'update']
  })
  return transitions.map(({ item, props, key }) => (
    <animated.div
      key={key}
      style={props}
      className={styles.singleChoice}
      onClick={e => Props.openNextChoices(e, item)}
    >
      <h3 className={styles.singleChoiceTitle}>{item.title} </h3>
      {item.subtitle && (
        <p className={styles.singleChoiceSubtitle}>{item.subtitle}</p>
      )}
    </animated.div>
  ))
}
