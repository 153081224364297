import { call, put } from 'redux-saga/effects'
import Api from '../utils/api'

export function * getPages () {
  try {
    const page = yield call(Api.get, `pages`)
    yield put({
      type: 'PAGES_SUCCEEDED',
      page: page
    })
  } catch (e) {
    yield put({ type: 'PAGES_FAILED', message: e.message })
  }
}

export function * getAppPages () {
  try {
    const pages = yield call(Api.get, `app_pages`)
    yield put({
      type: 'APP_PAGES_SUCCEEDED',
      pages: pages
    })
  } catch (e) {
    yield put({ type: 'APP_PAGES_FAILED', message: e.message })
  }
}

export function * getUitslagPages () {
  try {
    const pages = yield call(Api.get, `uitslag_pages`)
    yield put({
      type: 'UITSLAG_PAGES_SUCCEEDED',
      pages: pages
    })
  } catch (e) {
    yield put({ type: 'UITSLAG_PAGES_FAILED', message: e.message })
  }
}

export function * getAanvraagPages () {
  try {
    const pages = yield call(Api.get, `aanvraag_pages`)
    yield put({
      type: 'AANVRAAG_PAGES_SUCCEEDED',
      pages: pages
    })
  } catch (e) {
    yield put({
      type: 'AANVRAAG_PAGES_FAILED',
      message: e.message
    })
  }
}

export function * getPage (data) {
  try {
    const page = yield call(Api.get, `pages/${data.slug}`)
    yield put({
      type: 'PAGE_SUCCEEDED',
      page: page
    })
  } catch (e) {
    yield put({ type: 'PAGE_FAILED', message: e.message })
  }
}
