import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store'

import Website from 'containers/website'
import WebsiteHardcoded from 'containers/websiteHardcoded'
import Indicatie from 'containers/indicatie'
import App from 'containers/app'
import Aanvraag from '../components/main/aanvraag/aanvraag'
import Uitslag from '../components/main/uitslag/Uitslag'
import Moreinfo1 from '../components/main/moreinfo1/Moreinfo1'
import Second from '../components/main/Second/Second'
import Third from '../components/main/third/third'
import JongeLeeftijd from '../components/main/jongeLeeftijd/JongeLeeftijd'
import MeerInformatie from '../components/main/meerInformatie/MeerInformatie'
import Verwijzing from '../components/main/Verwijzing/Verwijzing'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import AnimatedSwitch from './animated_switch'
import GeenSpoed from '../components/main/GeenSpoed/GeenSpoed'

const createRoutes = () => (
  <Provider store={store}>
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup component='main'>
            <AnimatedSwitch key={location.key} location={location}>
              <Route path='/app/indicatie' component={Indicatie} />
              <Route path='/app/second' component={Second} />
              <Route path='/app/third' component={Third} />
              <Route path='/app/verwijzing' component={Verwijzing} />
              <Route path='/app/jongeleeftijd' component={JongeLeeftijd} />
              <Route path='/app/meerinformatie' component={MeerInformatie} />
              <Route path='/app/geenspoed' component={GeenSpoed} />
              <Route path='/app/aanvraag' component={Aanvraag} />
              <Route path='/app/uitslag' component={Uitslag} />
              <Route path='/app/moreinfo1' component={Moreinfo1} />
              <Route exact path='/' component={Website} />
              <Route exact path='/disclaimer' component={WebsiteHardcoded} />
              <Route exact path='/privacy' component={WebsiteHardcoded} />
              <Route path='/app' component={App} />
              <Route path='/:indication/:slug' component={Website} />
              <Route path='/:indication' component={Website} />
            </AnimatedSwitch>
          </TransitionGroup>
        )}
      />
    </Router>
  </Provider>
)

export default createRoutes
