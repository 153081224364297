import { combineReducers } from 'redux-immutable'
import pages from './pages/pages'
import isLoading from './pages/is_loading'
import error from './pages/error'

export default combineReducers({
  pages,
  isLoading,
  error
})
