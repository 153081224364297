import { combineReducers } from 'redux-immutable'
import page from './page/page'
import isLoading from './page/is_loading'
import error from './page/error'

export default combineReducers({
  page,
  isLoading,
  error
})
