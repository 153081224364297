import React from 'react'
import { Link } from 'react-router-dom'

import Radboudumc from 'images/radboud-logo.svg'
import MUMC from 'images/mumc-logo.png'

import './Footer.scss'

class Footer extends React.Component {
  render () {
    return (
      <div className='footer'>
        <div className='line' />
        <div className='container'>
          <div className='row justify-content-md-between align-items-md-center text-center'>
            <div className='col-md-5 text-md-left'>
              <Link
                to={'//www.radboudumc.nl/afdelingen/genetica'}
                target='_blank'
              >
                <img src={Radboudumc} className='footer-logo' width='120' />
              </Link>
              <Link to={'//klinischegenetica.mumc.nl/'} target='_blank'>
                <img src={MUMC} className='footer-logo' width='120' />
              </Link>
            </div>
            <div className='col-md-auto'>
              <span>© {new Date().getFullYear()}</span>
            </div>
            <div className='col-md-5 text-md-right'>
              <Link
                to={'/disclaimer'}
                className='font-weight-bold disclaimer-link'
              >
                Disclaimer
              </Link>
            </div>
          </div>
        </div>
        <div className='container text-center'>
          <span className='footer-text'>
            DNA-first is een zorgvernieuwingstraject van de afdelingen Genetica
            van het Radboudumc Nijmegen en het Maastricht UMC + .
          </span>
        </div>
      </div>
    )
  }
}

export default Footer
