import React from 'react'
import First from 'components/main/First/First'

class Indicatie extends React.Component {
  render () {
    return (
      <div className='App'>
        <First />
      </div>
    )
  }
}

export default Indicatie
