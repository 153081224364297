import { combineReducers } from 'redux-immutable'
import pages from './aanvraag_pages/pages'
import isLoading from './aanvraag_pages/is_loading'
import error from './aanvraag_pages/error'

export default combineReducers({
  pages,
  isLoading,
  error
})
