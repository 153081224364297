import React from 'react'
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap'
import { Link } from 'react-router-dom'

import FullscreenNav from '../FullscreenNav/FullscreenNav'
import logo from 'images/logo.svg'
import caretRightWhite from 'images/caret-right-white.svg'
import caretRight from 'images/caret-right.svg'
import caretLeft from 'images/caret-left.svg'

import Tooltip from '../tooltip/Tooltip'

import './NavBar.css.scss'

export default class NavBar extends React.Component {
  constructor (props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
      active: true,
      menuOpened: null,
      nestedOpened: null,
      newHeight: 0,
      thirdHeight: 0
    }
  }
  toggle () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  switchmodal () {}

  openUrl (e, url) {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      e.preventDefault()
      if (this.state.isOpen) {
        this.history.pushState(null, url)
      }
    }
  }

  showNestedPages (i, number, numberRoot) {
    i.preventDefault()
    let newValue = null
    let newHeight = 0
    if (number !== this.state.menuOpened) {
      newValue = number
    }
    if (number && numberRoot !== undefined) {
      let amountOfNestedPages = this.props.pages[numberRoot].children[number]
        .children.length
      newHeight = (amountOfNestedPages + 1) * 50
    }
    this.setState({
      menuOpened: numberRoot,
      nestedOpened: newValue,
      newHeight: newHeight
    })
  }

  showThirdNestedPages (i, num, number, numberRoot) {
    i.preventDefault()
    let newValue = null
    let newHeight = 0
    if (number !== this.state.menuOpened) {
      newValue = num
    }
    if (num && number && numberRoot !== undefined) {
      let amountOfNestedPages = this.props.pages[numberRoot].children[number]
        .children[num].children.length
      newHeight = (amountOfNestedPages + 1) * 50
    }
    this.setState({
      menuOpened: numberRoot,
      nestedOpened: number,
      thirdOpened: newValue,
      thirdHeight: newHeight
    })
  }

  render () {
    // let classes = ''
    // if (true) { classes += 'tooltexts ' } // this is just tooltext delete (s)
    const {
      menuOpened,
      thirdOpened,
      nestedOpened,
      newHeight,
      isOpen
    } = this.state
    let navViewArray = []
    let tooltipArray = []
    let nestedPagesArray = []
    let thirdNestedPagesArray = []
    if (this.props.pages instanceof Array) {
      if (this.props.pages.length > 0) {
        this.props.pages.map((page, rooti) => {
          tooltipArray = []
          nestedPagesArray = []
          if (!page.parent_id || page.parent_id === this.props.root.id) {
            if (page.children.length > 0) {
              page.children.map((page, i) => {
                if (page.children.length > 0) {
                  page.children.map((nestedPage, j) => {
                    if (nestedPage.children.length > 0) {
                      nestedPage.children.map((thirdNestedPage, k) => {
                        if (thirdNestedPage.live) {
                          thirdNestedPagesArray.push(
                            <Link
                              key={`${i}-${j}-${k}-nesteditem`}
                              to={`/${this.props.root.url}/${
                                thirdNestedPage.url
                              }`}
                              className='nav-link'
                            >
                              {thirdNestedPage.title}
                            </Link>
                          )
                        }
                      })
                    } else {
                      if (nestedPage.live) {
                        nestedPagesArray.push(
                          <Link
                            key={`${i}-${j}-nesteditem`}
                            to={`/${this.props.root.url}/${nestedPage.url}`}
                            className='nav-link'
                          >
                            {nestedPage.title}
                          </Link>
                        )
                      }
                    }
                    if (thirdNestedPagesArray.length > 0) {
                      if (nestedPage.live) {
                        nestedPagesArray.push(
                          <li
                            key={`${i}-${j}-nesteditem`}
                            className={'nested-item'}
                          >
                            <Link
                              to={`/${this.props.root.url}/${nestedPage.url}`}
                              className='nav-link'
                            >
                              {nestedPage.title}
                            </Link>
                            <Link
                              to='#'
                              className='arrow-link'
                              onClick={item =>
                                this.showThirdNestedPages(item, j, i, rooti)
                              }
                            >
                              <img className='nested-arrow' src={caretRight} />
                            </Link>
                            <div
                              className={`${i}-${j}-nested-pages third-pages ${thirdOpened ===
                                j}`}
                            >
                              <div
                                className='back-arrow'
                                onClick={item =>
                                  this.showThirdNestedPages(
                                    item,
                                    null,
                                    i,
                                    rooti
                                  )
                                }
                              >
                                <img className='nested-arrow' src={caretLeft} />
                                <p>Ga terug</p>
                              </div>
                              {thirdNestedPagesArray}
                            </div>
                          </li>
                        )
                      }
                    }
                    thirdNestedPagesArray = []
                  })
                  if (page.live) {
                    tooltipArray.push(
                      <li key={`${i}-nesteditem`} className={'nested-item'}>
                        <Link
                          to={`/${this.props.root.url}/${page.url}`}
                          className='nav-link'
                        >
                          {page.title}
                        </Link>
                        <Link
                          to='#'
                          className='arrow-link'
                          onClick={item => this.showNestedPages(item, i, rooti)}
                        >
                          <img className='nested-arrow' src={caretRight} />
                        </Link>
                        <div
                          className={`${i}-nested-pages nested-pages ${nestedOpened ===
                            i}`}
                        >
                          <div
                            className='back-arrow'
                            onClick={item => this.showNestedPages(item, null)}
                          >
                            <img className='nested-arrow' src={caretLeft} />
                            <p>Ga terug</p>
                          </div>
                          {nestedPagesArray}
                        </div>
                      </li>
                    )
                  }
                } else {
                  if (page.live) {
                    tooltipArray.push(
                      <li key={`${i}-nav-item`}>
                        <Link
                          to={`/${this.props.root.url}/${page.url}`}
                          className='nav-link'
                        >
                          {page.title}
                        </Link>
                      </li>
                    )
                  }
                }
              })
              if (page.live) {
                navViewArray.push(
                  <Link
                    key={`${rooti}-navitem`}
                    to={`/${this.props.root.url}/${page.children[0].url}`}
                    onClick={e => this.openUrl(e, `/${page.children[0].url}`)}
                  >
                    <Tooltip>
                      <div className='tool nav-link'>
                        <span className='nav-link-text'>
                          {page.title}
                          <img src={caretRightWhite} />
                        </span>
                        <span className='tooltext'>
                          <ul
                            className='tools'
                            style={
                              menuOpened === rooti
                                ? { height: newHeight }
                                : {
                                  height:
                                      this.props.pages[rooti].children.length *
                                      50
                                }
                            }
                          >
                            {tooltipArray}
                          </ul>
                        </span>
                      </div>
                    </Tooltip>
                  </Link>
                )
              }
              // tooltipArray = []
            }
          }
          if (tooltipArray.length > 0) {
            // navViewArray.push(
            //   <Link key={`${i}-tooltip-navitem`} to={`/${page.url}`}>
            //     <Tooltip>
            //       <div className='tool nav-link'>
            //         {page.title}<img src={arrow} />
            //         <span className='tooltext'>
            //           <ul className='tools'>
            //             {tooltipArray}
            //           </ul>
            //         </span>
            //       </div>
            //     </Tooltip>
            //   </Link>
            // )
            // tooltipArray = []
          } else {
            if (page.live) {
              navViewArray.push(
                <Link
                  key={`${rooti}-nav--item`}
                  to={`/${this.props.root.url}/${page.url}`}
                  className='nav-link'
                >
                  {page.title}
                </Link>
              )
            }
          }
        })
      }
    }

    return (
      <Navbar light expand='md' className='container'>
        <Link to='/' className='navbar-brand'>
          <img className='navbar-brand-back' src={caretLeft} />
          <img src={logo} />
          {this.props.root ? (
            <p className='navbar-brand-indication'>{this.props.root.title}</p>
          ) : null}
        </Link>
        <Collapse className='d-none d-md-block' navbar>
          <Nav className='ml-auto' navbar>
            {this.props.root ? (
              <Link
                key={`${this.props.root.id}-nav--item`}
                to={`/${this.props.root.url}`}
                className='nav-link'
              >
                Home
              </Link>
            ) : null}
            {navViewArray}
          </Nav>
        </Collapse>
        <div className='d-md-none mobile-nav'>
          <NavbarToggler onClick={this.toggle} />
          <FullscreenNav
            changeOpen={() => this.toggle()}
            pages={this.props.pages}
            root={this.props.root}
            isOpen={isOpen}
          />
        </div>
      </Navbar>
    )
  }
}
