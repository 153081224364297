import React from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

import './meerInfoModal.scss'

class ModalExample extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modal: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    this.setState({
      modal: !this.state.modal
    })
  }

  render () {
    return (
      <div className='meer-modal'>
        <Button color='transparent' onClick={this.toggle}>
          {this.props.title ? this.props.title : 'Meer informatie'}
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader className='header-m' toggle={this.toggle}>
            s
          </ModalHeader>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default ModalExample
