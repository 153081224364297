import React from 'react'
import { Link } from 'react-router-dom'

import HeaderMain from '../header-main/HeaderMain'
import Information from '../Information/Information'
import results from 'images/results.svg'

import './moreinfo1.scss'

class Moreinfo1 extends React.Component {
  render () {
    return (
      <div className='moreinfo1'>
        <HeaderMain title={'Uitslag'} image={results} />
        <div className='content-Moreinfo1'>
          <div>
            <Link to='/app/uitslag'>
              <p className='mdo'>Bespreken met patient & MDO</p>
            </Link>
            <Information
              titel={'Meer informatie 1'}
              paragraph1={
                'Consequatur quo quo voluptatem dolore recusandae autem non voluptatibus. Perspiciatis iusto nam ipsam aut deleniti quod. Iusto mollitia accusantium odio illo unde consequuntur rerum ut. Enim reiciendis aliquid aut. At voluptatem cumque ipsum assumenda quod molestias adipisci consequuntur. '
              }
              paragraph2={
                'Consequatur quo quo voluptatem dolore recusandae autem non voluptatibus. Perspiciatis iusto nam ipsam aut deleniti quod. Iusto mollitia accusantium odio illo unde consequuntur rerum ut. Enim reiciendis aliquid aut. At voluptatem cumque ipsum assumenda quod molestias adipisci consequuntur.'
              }
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Moreinfo1
