import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import Root from './Root'
import NavBar from '../components/website/header/NavBar/NavBar'
import Footer from '../components/website/footer/Footer'
import Home from '../components/website/main/home/Home'

class Website extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      content: null,
      allPages: [],
      profPage: false
    }
  }

  componentWillReceiveProps (newProps) {
    let pages = newProps.pages.toJS()
    if (this.props.slug !== newProps.slug && pages) {
      this.getPage(newProps.indication, newProps.slug)
    }
    if (this.props.indication !== newProps.indication && pages) {
      this.getPage(newProps.indication, newProps.slug)
    }
    if (this.props.pages !== pages && pages) {
      pages = pages.pages
      pages = _.sortBy(pages, ['position'])
      pages.map(page => {
        if ('children' in page) {
          if (page.children.length > 0) {
            page.children = _.sortBy(page.children, ['position'])
            page.children.map(childPage => {
              if ('children' in childPage) {
                if (childPage.children.length > 0) {
                  childPage.children = _.sortBy(childPage.children, [
                    'position'
                  ])
                }
              }
              return childPage
            })
          }
        }
        return page
      })
      this.setState({
        allPages: pages
      })
      if (!newProps.slug && !newProps.indication && pages.length) {
        this.setState({
          content: pages[0],
          profPage: pages[0].ancestry
            ? pages[0].ancestry.indexOf('17') > -1
            : false
        })
      } else {
        this.getPage(newProps.indication, newProps.slug, pages)
      }
    }
  }

  getPage (indication, slug, pages) {
    let allPages = this.state.allPages
    if (pages) {
      allPages = pages
    }

    if (indication && !slug && allPages.length) {
      let foundPagelink = _.find(this.getPagesWithChildren(allPages), {
        url: indication
      })
      if (foundPagelink) {
        this.setState({
          content: foundPagelink,
          profPage: foundPagelink.ancestry
            ? foundPagelink.ancestry.indexOf('17') > -1
            : false
        })
        return
      }
    }

    if (allPages.length && allPages[0].is_root) {
      allPages = allPages[0].children.find(page => page.url === indication)
        .children
    }

    if (slug && allPages) {
      let foundPagelink = _.find(this.getPagesWithChildren(allPages), {
        url: slug
      })
      if (foundPagelink) {
        this.setState({
          content: foundPagelink,
          profPage: foundPagelink.ancestry
            ? foundPagelink.ancestry.indexOf('17') > -1
            : false
        })
      } else {
        allPages.map(page => {
          let foundPagelink = _.find(page.children, {
            url: slug
          })
          if (foundPagelink) {
            this.setState({
              content: foundPagelink,
              profPage: foundPagelink.ancestry
                ? foundPagelink.ancestry.indexOf('17') > -1
                : false
            })
          } else {
            page.children.map(childPage => {
              let foundPagelink = _.find(childPage.children, {
                url: slug
              })
              if (foundPagelink) {
                this.setState({
                  content: foundPagelink,
                  profPage: foundPagelink.ancestry
                    ? foundPagelink.ancestry.indexOf('17') > -1
                    : false
                })
              } else {
                childPage.children.map(childPage => {
                  let foundPagelink = _.find(childPage.children, {
                    url: slug
                  })
                  if (foundPagelink) {
                    this.setState({
                      content: foundPagelink,
                      profPage: foundPagelink.ancestry
                        ? foundPagelink.ancestry.indexOf('17') > -1
                        : false
                    })
                  }
                })
              }
            })
          }
        })
      }
    }
  }

  componentWillMount () {
    // let allPages = this.props.pages.toJS().pages
    // if (allPages.length === 0) {
    this.props.requestPages()
    this.props.requestAppPages()
    // }
    this.getPage(this.props.indication, this.props.slug)
  }

  getPagesWithChildren (pages) {
    let pagesWithChildren = []
    pages.map(page => {
      pagesWithChildren.push(page)
      if (page.children.length > 0) {
        // Get all the children of the page and keep going further down the tree
        const childPages = this.getPagesWithChildren(page.children)
        if (childPages.length > 0) {
          pagesWithChildren.push(...childPages)
        }
      }
    })
    return pagesWithChildren
  }

  getRootParent (page) {
    if (!page) return null
    let id = page.parent_id
    if (id === 0) {
      return page
    }
    let parent = _.find(this.getPagesWithChildren(this.state.allPages), {
      id: id
    })
    if (parent) {
      return this.getRootParent(parent)
    }
    return null
  }

  getSectionParent (page) {
    if (!page) return null
    let id = page.parent_id
    if (id === 0) {
      return page
    }
    let parent = _.find(this.getPagesWithChildren(this.state.allPages), {
      id: id
    })
    if (parent?.ancestry?.split('/').length === 2) {
      return parent
    }
    if (parent) {
      return this.getRootParent(parent)
    }
    return null
  }

  render () {
    if (this.state.content && this.state.content.is_root) {
      return <Root content={this.state.content} />
    }

    let allPages = this.state.allPages
    // Get the ocrrect root item based on the page content id
    const root = this.getRootParent(this.state.content)
    const section = this.getSectionParent(this.state.content)

    if (
      this.state.allPages.length > 0 &&
      this.state.allPages[0].children.length > 0
    ) {
      if (root != null) {
        allPages = this.getRootParent(this.state.content).children
      }
    }

    return (
      <React.Fragment>
        <NavBar root={root} pages={allPages} />
        <div className='container'>
          <Home
            content={this.state.content}
            root={root}
            section={section}
            allPages={allPages}
            profPage={this.state.profPage}
          />
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    slug: ownProps.match.params.slug,
    indication: ownProps.match.params.indication,
    pages: state.get('pages'),
    page: state.get('page')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestPage: slug => dispatch({ type: 'PAGE_REQUESTED', slug: slug }),
    requestPages: () => dispatch({ type: 'PAGES_REQUESTED' }),
    requestAppPages: () => dispatch({ type: 'APP_PAGES_REQUESTED' })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Website)
