import React from 'react'
import { Link } from 'react-router-dom'

import logo from 'images/logo.svg'
import close from 'images/close.png'

import styles from './Header.module.css'

class Header extends React.Component {
  render () {
    return (
      <div className={`${styles.head} container-fluid`}>
        <div className='row justify-content-between'>
          <div className='col-md-auto'>
            <div className={`logo`}>
              <a href='/'>
                <img
                  className={`${styles.logo}`}
                  src={logo}
                  alt='DNA-first logo'
                />
              </a>
            </div>
          </div>
          <div className='col-md-auto'>
            <div className='d-none d-md-block'>
              <Link to='#' onClick={() => window.close()}>
                <span className={styles.close}>
                  Sluiten <img src={close} className={styles.closeImg} />{' '}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
