import 'regenerator-runtime/runtime'
import { createStore, applyMiddleware, compose } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { persistStore, autoRehydrate } from 'redux-persist-immutable'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { Map } from 'immutable'

import rootReducer from '../reducers'
import rootSaga from '../sagas'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['pages', 'page']
}

const initialState = Map()
const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]

if (process.env.NODE_ENV === `development`) {
  const loggerMiddleware = createLogger({
    collapsed: true,
    stateTransformer: state => state.toJS()
  })
  middleware.push(loggerMiddleware)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const enhancers = [applyMiddleware(...middleware), autoRehydrate()]

let store = createStore(persistedReducer, initialState, compose(...enhancers))

let persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }
