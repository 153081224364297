import { takeLatest } from 'redux-saga/effects'
import {
  getPage,
  getPages,
  getAppPages,
  getAanvraagPages,
  getUitslagPages
} from './pages'

function * rootSaga () {
  yield takeLatest('PAGE_REQUESTED', getPage)
  yield takeLatest('PAGES_REQUESTED', getPages)
  yield takeLatest('APP_PAGES_REQUESTED', getAppPages)
  yield takeLatest('UITSLAG_PAGES_REQUESTED', getUitslagPages)
  yield takeLatest('AANVRAAG_PAGES_REQUESTED', getAanvraagPages)
}

export default rootSaga
