import React from 'react'
import { Link } from 'react-router-dom'

import caretRight from 'images/caret-right.svg'
import caretLeft from 'images/caret-left.svg'

import styles from './FullscreenNav.module.css'

export default class FullscreenNav extends React.Component {
  constructor (props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
      active: true,
      currentRoot: null,
      currentNested: null,
      currentThird: null,
      menuOpened: null,
      newHeight: 0
    }
  }
  toggle () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  switchmodal () {}

  showNestedPages (i, number) {
    i.preventDefault()
    let newValue = null
    if (number !== this.state.currentRoot) {
      newValue = number
    }
    this.setState({
      currentRoot: newValue
    })
  }

  showNestedChildrenPages (i, number, numberRoot) {
    i.preventDefault()
    let newValue = null
    if (number !== this.state.currentNested) {
      newValue = number
    }
    this.setState({
      currentNested: newValue
    })
  }

  showNestedChildrenThirdPages (i, num, number, numberRoot) {
    i.preventDefault()
    let newValue = null
    if (num !== this.state.currentThird) {
      newValue = num
    }
    this.setState({
      currentThird: newValue
    })
  }

  render () {
    // let classes = ''
    // if (true) { classes += 'tooltexts ' } // this is just tooltext delete (s)
    const { currentRoot, currentNested, currentThird } = this.state
    let navViewArray = []
    let tooltipArray = []
    let nestedPagesArray = []
    let nestedThirdPagesArray = []
    if (this.props.pages instanceof Array) {
      if (this.props.pages.length > 0) {
        this.props.pages.map((page, rooti) => {
          tooltipArray = []
          nestedPagesArray = []
          if (!page.parent_id || page.parent_id === this.props.root.id) {
            if (page.children.length > 0) {
              page.children.map((page, i) => {
                if (page.children.length > 0) {
                  page.children.map((nestedPage, j) => {
                    if (nestedPage.children.length > 0) {
                      nestedPage.children.map((nestedThirdPage, k) => {
                        if (nestedThirdPage.live) {
                          nestedThirdPagesArray.push(
                            <Link
                              className={styles.pageLink + ' nav-link'}
                              key={`${i}-${j}-${k}-nesteditem`}
                              to={`/${this.props.root.url}/${
                                nestedThirdPage.url
                              }`}
                            >
                              {nestedThirdPage.title}
                            </Link>
                          )
                        }
                      })
                    }
                    if (nestedThirdPagesArray.length > 0) {
                      if (nestedPage.live) {
                        nestedPagesArray.push(
                          <li
                            key={`${i}-${j}-nesteditem`}
                            className={styles.pageLink + ' nested-item'}
                          >
                            <Link
                              className={styles.pageLink + ' nav-link'}
                              to={`/${this.props.root.url}/${nestedPage.url}`}
                              onClick={item =>
                                this.showNestedChildrenThirdPages(
                                  item,
                                  j,
                                  i,
                                  rooti
                                )
                              }
                            >
                              {nestedPage.title}
                            </Link>
                            <img
                              className='nested-arrow'
                              src={caretRight}
                              onClick={item =>
                                this.showNestedChildrenThirdPages(
                                  item,
                                  j,
                                  i,
                                  rooti
                                )
                              }
                            />
                            <div
                              className={styles.overlaySubNav}
                              style={
                                j === currentThird
                                  ? { width: '100vw' }
                                  : { width: '0vw' }
                              }
                            >
                              <div className={styles.overlayContentSubNav}>
                                <div
                                  className='back-arrow'
                                  onClick={item =>
                                    this.showNestedChildrenThirdPages(
                                      item,
                                      null,
                                      null
                                    )
                                  }
                                >
                                  <img
                                    className='nested-arrow'
                                    src={caretLeft}
                                  />
                                  <p>Ga terug</p>
                                </div>
                                {nestedThirdPagesArray}
                              </div>
                            </div>
                          </li>
                        )
                      }
                    } else {
                      if (nestedPage.live) {
                        nestedPagesArray.push(
                          <Link
                            className={styles.pageLink + ' nav-link'}
                            key={`${i}-${j}-nesteditem`}
                            to={`/${this.props.root.url}/${nestedPage.url}`}
                          >
                            {nestedPage.title}
                          </Link>
                        )
                      }
                    }
                    nestedThirdPagesArray = []
                  })
                  if (page.live) {
                    tooltipArray.push(
                      <li
                        key={`${i}-nesteditem`}
                        className={styles.pageLink + ' nested-item'}
                      >
                        <Link
                          className={styles.pageLink + ' nav-link'}
                          to={`/${this.props.root.url}/${page.url}`}
                          onClick={item =>
                            this.showNestedChildrenPages(item, i, rooti)
                          }
                        >
                          {page.title}
                        </Link>
                        <img
                          className='nested-arrow'
                          src={caretRight}
                          onClick={item =>
                            this.showNestedChildrenPages(item, i, rooti)
                          }
                        />
                        <div
                          className={styles.overlaySubNav}
                          style={
                            i === currentNested
                              ? { width: '100vw' }
                              : { width: '0vw' }
                          }
                        >
                          <div className={styles.overlayContentSubNav}>
                            <div
                              className='back-arrow'
                              onClick={item =>
                                this.showNestedChildrenPages(item, null, null)
                              }
                            >
                              <img className='nested-arrow' src={caretLeft} />
                              <p>Ga terug</p>
                            </div>
                            {nestedPagesArray}
                          </div>
                        </div>
                      </li>
                    )
                  }
                } else {
                  if (page.live) {
                    tooltipArray.push(
                      <li key={`${i}-nav-item`}>
                        <Link
                          className={styles.pageLink + ' nav-link'}
                          to={`/${this.props.root.url}/${page.url}`}
                        >
                          {page.title}
                        </Link>
                      </li>
                    )
                  }
                }
              })
              if (page.live) {
                navViewArray.push(
                  <Link
                    className={styles.pageLink}
                    key={`${rooti}-navitem`}
                    to={`/${this.props.root.url}/${page.url}`}
                    onClick={item => this.showNestedPages(item, rooti)}
                  >
                    <div className='tool nav-link'>
                      {page.title}
                      <div
                        className={styles.overlaySubNav}
                        style={
                          rooti === currentRoot
                            ? { width: '100vw' }
                            : { width: '0vw' }
                        }
                      >
                        <div className={styles.overlayContentSubNav}>
                          <div
                            className='back-arrow'
                            onClick={item => this.showNestedPages(item, null)}
                          >
                            <img className='nested-arrow' src={caretLeft} />
                            <p>Ga terug</p>
                          </div>
                          {tooltipArray}
                        </div>
                      </div>
                    </div>
                    <img
                      className='nested-arrow'
                      src={caretRight}
                      onClick={item => this.showNestedPages(item, rooti)}
                    />
                  </Link>
                )
              }
              // tooltipArray = []
            }
          }
          if (tooltipArray.length > 0) {
            // navViewArray.push(
            //   <Link key={`${i}-tooltip-navitem`} to={`/${page.url}`}>
            //     <Tooltip>
            //       <div className='tool nav-link'>
            //         {page.title}<img src={arrow} />
            //         <span className='tooltext'>
            //           <ul className='tools'>
            //             {tooltipArray}
            //           </ul>
            //         </span>
            //       </div>
            //     </Tooltip>
            //   </Link>
            // )
            // tooltipArray = []
          } else {
            if (page.live) {
              navViewArray.push(
                <Link
                  className={styles.pageLink + ' nav-link'}
                  key={`${rooti}-nav--item`}
                  to={`/${this.props.root.url}/${page.url}`}
                >
                  {page.title}
                </Link>
              )
            }
          }
        })
      }
    }

    let navView = null
    this.props.subNav
      ? (navView = (
        <div
          className={styles.overlaySubNav}
          style={this.props.isOpen ? { width: '100vw' } : { width: '0vw' }}
        >
          <div className={styles.overlayContent}>{navViewArray}</div>
        </div>
      ))
      : (navView = (
        <div
          className={styles.overlay}
          style={this.props.isOpen ? { height: '100vh' } : { height: '0vh' }}
        >
          <div className={styles.overlayContent}>
            <Link to='#' onClick={() => this.props.changeOpen()}>
              <p>Sluiten</p>
            </Link>
            {this.props.root ? (
              <Link
                key={`${this.props.root.id}-navitem`}
                to={`/${this.props.root.url}`}
                className={styles.pageLink + ' nav-link'}
              >
                <div className='tool nav-link'>Home</div>
              </Link>
            ) : null}
            {navViewArray}
            {/* <NavItem>
              <Link className={styles.appLink} to='/app'>
                <NavLink className='noHover'>DNA-onderzoek aanvragen <img src={arrow} /> </NavLink>
              </Link>
            </NavItem> */}
          </div>
        </div>
      ))

    return navView
  }
}
