import React from 'react'
import './Blueblob.scss'

class Blueblob extends React.Component {
  render () {
    let backClassName = `back ${this.props.profPage && 'profpage'}`
    return (
      <div className='Blueblob'>
        <div
          className={backClassName}
          dangerouslySetInnerHTML={{ __html: this.props.paragraph }}
        />
      </div>
    )
  }
}

export default Blueblob
