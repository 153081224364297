// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '@babel/polyfill'
import 'react-app-polyfill/ie9'
import ReactDOM from 'react-dom'
import createRoutes from 'utils/routes'
// import * as serviceWorker from './serviceWorker'

import 'styles/application.css'

ReactDOM.render(createRoutes(), document.getElementById('app'))
// serviceWorker.register()
