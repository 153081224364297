import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import Information from '../Information/Information'
import HeaderMain from '../header-main/HeaderMain'
import ProgressNav from '../progressNav/ProgressNav'

import signpost from 'images/signpost.svg'

import './third.scss'

class Third extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      current: null
    }
  }

  componentWillReceiveProps (nextProps) {
    this.props !== nextProps && this.getPage()
  }

  componentWillMount () {
    this.props.requestAppPages()
    this.getPage()
  }

  getPage () {
    let pages = this.props.appPages.toJS()
    pages = pages.pages
    let foundPagelink = _.find(pages, {
      url: 'third'
    })
    if (foundPagelink) {
      this.setState({
        current: foundPagelink
      })
    }
  }

  render () {
    return (
      <div className='Aanvraag'>
        <HeaderMain title={'Indicatie'} image={signpost} />
        <ProgressNav active={3} steps={3} />
        {this.state.current !== null ? (
          <div className='container'>
            <Information
              title={this.state.current.title}
              paragraph1={this.state.current.content}
              popup={this.state.current.more}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    appPages: state.get('appPages')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestAppPages: () =>
      dispatch({
        type: 'APP_PAGES_REQUESTED'
      })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Third)
