import React from 'react'
import { Link } from 'react-router-dom'

import { CSSTransitionGroup } from 'react-transition-group'

import InstallPopup from '../../installPopup/InstallPopup'

import signpost from 'images/signpost-blue.svg'
import letter from 'images/letter-blue.svg'
import result from 'images/result-blue.svg'
import caretRight from 'images/caret-right.svg'
import appIcon from 'images/app-icon.png'

import './Home.css.scss'

class Home extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showInstallMessage: false
    }
  }

  componentDidMount () {
    this.checkIos()
    console.log(this.isMobile())
  }

  // Detects if device is in standalone mode
  isInStandaloneMode () {
    return 'standalone' in window.navigator && window.navigator.standalone
  }

  isMobile () {
    return navigator.userAgent.indexOf('Mobile') > -1
  }

  checkIos () {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone

    const popupAlreadyOpened = () =>
      window.localStorage.getItem('installPopupOpened')

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode() && !popupAlreadyOpened()) {
      this.setState({
        showInstallMessage: true
      })
    }
  }

  closePopup () {
    window.localStorage.setItem('installPopupOpened', true)
    this.setState({
      showInstallMessage: false
    })
  }

  openPopup () {
    this.setState({
      showInstallMessage: true
    })
  }

  render () {
    const host = this.isInStandaloneMode() ? '//dna-first.nl' : ''
    // const openInNewTab = this.isInStandaloneMode() ? '' : '_blank'
    return (
      <div className='question-container'>
        <div className='intro-container container-fluid'>
          <h5>Instructie-app voor zorgprofessionals</h5>
        </div>
        <div className='buttons container-fluid'>
          <div>
            <Link to={`/app/indicatie`}>
              <div className='button'>
                <h3 className='d-none d-md-block'>Indicatie</h3>
                <img src={signpost} />
              </div>
              <h3 className='mobile-label d-md-none'>Indicatie</h3>
            </Link>
            <Link
              className='d-none d-md-block'
              to={`${host}/indicaties-dna-diagnostiek`}
              target='_blank'
            >
              <div className='text-link-container'>
                <span className='text-link'>Meer informatie</span>
                <img src={caretRight} />
              </div>
            </Link>
          </div>
          <div>
            <Link to='/app/aanvraag'>
              <div className='button'>
                <h3 className='d-none d-md-block'>Aanvraag</h3>
                <img src={letter} />
              </div>
              <h3 className='mobile-label d-md-none'>Aanvraag</h3>
            </Link>
            <Link
              className='d-none d-md-block'
              to={`${host}/dna-diagnostiek-aanvragen`}
              target='_blank'
            >
              <div className='text-link-container'>
                <span className='text-link'>Meer informatie</span>
                <img src={caretRight} />
              </div>
            </Link>
          </div>
          <div>
            <Link to='/app/uitslag'>
              <div className='button'>
                <h3 className='d-none d-md-block'>Uitslag</h3>
                <img src={result} />
              </div>
              <h3 className='mobile-label d-md-none'>Uitslag</h3>
            </Link>
            <Link
              className='d-none d-md-block'
              to={`${host}/interpretatie-uitslag`}
              target='_blank'
            >
              <div className='text-link-container'>
                <span className='text-link'>Meer informatie</span>
                <img src={caretRight} />
              </div>
            </Link>
          </div>
        </div>
        <div className='bottom-link-container'>
          <Link to={`${host}/over-dna-first`} target={'_blank'}>
            <div className='text-link-container extra-margin-bottom'>
              <span className='text-link-extended'>Website DNA-First</span>
              <img src={caretRight} />
            </div>
          </Link>
          <Link to='//order.radboudumc.nl/DNA-first' target='_blank'>
            <div className='text-link-container extra-margin-bottom no-margin-bottom-mobile'>
              <span className='text-link-extended'>
                Aanvraagformulier DNA-onderzoek
              </span>
              <img src={caretRight} />
            </div>
          </Link>
          {/* <Link className={'d-none d-md-block'} to={`${host}`} target='_blank'>
            <div className='text-link-container'>
              <span className='text-link-extended'>Zakkaartje</span>
              <img src={caretRight} />
            </div>
          </Link> */}
        </div>
        {!this.isInStandaloneMode() &&
          this.isMobile() && (
          <div onClick={() => this.openPopup()} className='install-btn'>
            <h5>Download de app</h5>
            <img className='app-icon' src={appIcon} />
          </div>
        )}
        <CSSTransitionGroup
          transitionName='slideUp'
          transitionEnterTimeout={2050}
          transitionLeaveTimeout={350}
        >
          {this.state.showInstallMessage ? (
            <InstallPopup closePopup={() => this.closePopup()} />
          ) : null}
        </CSSTransitionGroup>
      </div>
    )
  }
}

export default Home
