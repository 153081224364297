import React, { useState } from 'react'
import { useTransition, animated } from 'react-spring'

import ModalExample from '../home/modal-meerinfo/meerInfoModal'

import book from 'images/book.svg'
import caretRight from 'images/caret-right.svg'
import caretLeft from 'images/caret-left.svg'

import styles from './Information.module.css'

export default function Information (Props) {
  const [pageOpened, setPageOpened] = useState(0)
  const [prev, setPrev] = useState(false)

  let content = Props.paragraph1
  if (pageOpened === 1) {
    content = Props.paragraph2
  } else if (pageOpened === 2) {
    content = Props.paragraph3
  }

  let maxContent = Props.paragraph3 ? 2 : 1

  const transitions = useTransition(content, item => pageOpened, {
    from: {
      position: 'absolute',
      transform: `translate3d(${!prev ? '-' : ''}400px,0,5px)`,
      opacity: 0
    },
    enter: {
      transform: 'translate3d(0,0px,0)',
      opacity: 1
    },
    leave: {
      transform: `translate3d(${prev ? '-' : ''}400px,0,5px)`,
      opacity: 0
    },
    native: true,
    trail: 100,
    order: ['leave', 'enter', 'update']
  })

  const openPrevPage = page => {
    setPageOpened(page)
    setPrev(true)
  }

  const openNextPage = page => {
    setPageOpened(page)
    setPrev(false)
  }

  return (
    <div
      style={Props.style && Props.style}
      className={
        Props.fullScreen
          ? styles.informationContentFullScreen
          : styles.informationContent
      }
    >
      <div>
        {transitions.map(
          ({ item, props }) =>
            item && (
              <animated.div style={props}>
                <div
                  className={`${
                    Props.fullScreen
                      ? styles.informationContentFullScreen
                      : styles.informationContent
                  } container`}
                >
                  <h3>{Props.title}</h3>
                  {Props.subtitle && (
                    <p className={`${styles.informationSubtitle}`}>
                      {Props.subtitle}
                    </p>
                  )}
                  <div
                    className={`${styles.informationContentInner}`}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                  {Props.popup &&
                    Props.popup !== '' && (
                    <div key='popup' className='moreinfo'>
                      <img src={book} />
                      <span className='modals'>
                        <ModalExample content={Props.popup} />
                      </span>
                    </div>
                  )}
                  {Props.paragraph3Extra &&
                    pageOpened === 2 &&
                    Props.paragraph3Extra}
                  {Props.children}
                </div>
              </animated.div>
            )
        )}
        <div className={`${styles.bottomBar} container`}>
          <div>
            {Props.showNext &&
              pageOpened - 1 > -1 && (
              <div
                onClick={() => openPrevPage(pageOpened - 1)}
                className={styles.pageBtnLeft}
                style={Props.fullScreen ? { marginLeft: '10px' } : {}}
              >
                <img src={caretLeft} />
                Terug
              </div>
            )}
            {Props.showNext &&
              maxContent >= pageOpened + 1 && (
              <div
                onClick={() => openNextPage(pageOpened + 1)}
                className={styles.pageBtn}
                style={Props.fullScreen ? { marginRight: '10px' } : {}}
              >
                Lees verder
                <img src={caretRight} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
