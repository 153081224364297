import React from 'react'

import Footer from '../components/website/footer/Footer'
import caretRight from 'images/caret-right.svg'
import logo from 'images/logo.svg'
import './Root.scss'

export default class Root extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      content: props.content
    }
  }
  componentWillReceiveProps (newProps) {
    this.setState({
      content: newProps.content
    })
  }

  render () {
    if (!this.state.content) return null

    const visiblePages = this.state.content.children.filter(page => page.live)

    return (
      <React.Fragment>
        <div className='root-wrapper'>
          <div className='root-container'>
            <img className='logo' src={logo} />

            <h3>DNA-Onderzoek door behandelend specialist</h3>
            <div className='link-container'>
              {visiblePages.map((child, index) => (
                <a key={child.id} href={`/${child.url}`}>
                  <div
                    className={[
                      'text-link-container',
                      index === visiblePages.length - 1
                        ? ''
                        : 'extra-margin-bottom'
                    ].join(' ')}
                  >
                    <span className='text-link-extended'>{child.title}</span>
                    <img src={caretRight} />
                  </div>
                </a>
              ))}
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}
