import React from 'react'

import caretRight from 'images/caret-right-white.svg'

import './Jumbotron.scss'

class Jumbotron extends React.Component {
  render () {
    const hasCta =
      (this.props.section?.page_cta_link?.length &&
        this.props.section?.page_cta_text?.length) ||
      (this.props.section?.page_cta_2_link?.length &&
        this.props.section?.page_cta_2_text?.length) ||
      (this.props.section?.page_cta_3_link?.length &&
        this.props.section?.page_cta_3_text?.length)

    var containerClass = `jumbotron-container ${(this.props.profPage ||
      this.props.section?.page_blob_color === 'dark') &&
      'prof'}`
    var contentClass = `jumbotron-content ${!hasCta && 'no-cta'}`

    return (
      <div className={containerClass}>
        <div className={contentClass}>
          <div className='jumbotron-titles'>
            {this.props.content ? (
              <h2
                dangerouslySetInnerHTML={{
                  __html: this.props.section.page_heading
                }}
              />
            ) : null}
          </div>
          {hasCta ? (
            <div className='jumbotron-buttons'>
              {this.props.section?.page_cta_link?.length ? (
                <a href={this.props.section.page_cta_link} target={"_blank"}>
                  <span className='d-md-inline-block'>
                    {this.props.section.page_cta_text}
                  </span>{' '}
                  <img src={caretRight} />
                </a>
              ): null}
              {this.props.section?.page_cta_2_link?.length ? (
                <a href={this.props.section.page_cta_2_link} target={"_blank"}>
                  <span className='d-md-inline-block'>
                    {this.props.section.page_cta_2_text}
                  </span>{' '}
                  <img src={caretRight} />
                </a>
              ): null}
              {this.props.section?.page_cta_3_link?.length ? (
                <a href={this.props.section.page_cta_3_link} target={"_blank"}>
                  <span className='d-md-inline-block'>
                    {this.props.section.page_cta_3_text}
                  </span>{' '}
                  <img src={caretRight} />
                </a>
              ): null}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default Jumbotron
