import React from 'react'

import Form from '../form/form'
import ProgressNav from '../progressNav/ProgressNav'
import HeaderMain from '../header-main/HeaderMain'
import signpost from 'images/signpost.svg'

import './First.css'

class First extends React.Component {
  render () {
    return (
      <div>
        <HeaderMain title={'Indicatie'} image={signpost} />
        <ProgressNav active={1} steps={3} />
        <Form />
      </div>
    )
  }
}

export default First
