import fetch from 'isomorphic-fetch'

const makeRequest = (method, path, data = {}) => {
  let body
  if (method === 'GET') {
    body = undefined
  } else {
    body = JSON.stringify(data)
  }
  const request = fetch(`/api/${path}`, {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.head.querySelector('[name=csrf-token]').content
    },
    body: body,
    credentials: 'same-origin'
  }).then(function (response) {
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json().then(data => {
        return data
      })
    }
  })
  return request
}

const get = (...args) => {
  return makeRequest('GET', ...args)
}

const put = (...args) => {
  return makeRequest('PUT', ...args)
}

const post = (...args) => {
  return makeRequest('POST', ...args)
}

const patch = (...args) => {
  return makeRequest('PATCH', ...args)
}

const destroy = (...args) => {
  return makeRequest('DELETE', ...args)
}

export default {
  get,
  put,
  post,
  patch,
  destroy
}
