import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Information from '../Information/Information'
import HeaderMain from '../header-main/HeaderMain'
import result from 'images/result-blue.svg'
import letter from 'images/letter.svg'

import './aanvraag.scss'

class Aanvraag extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      current: null
    }
  }

  componentWillMount () {
    this.props.requestAanvraagPages()
    if (this.props.page) {
      let page = this.props.page.pages
      if (this.props.page !== page && page) {
        this.setState({
          current: page[0]
        })
      }
    }
  }

  componentWillReceiveProps (newProps) {
    let page = newProps.page.toJS()
    if (this.props.page !== page && page) {
      this.setState({
        current: page.pages[0]
      })
    }
  }

  render () {
    return (
      <div className='Aanvraag'>
        <HeaderMain title={'Aanvraag'} image={letter} />
        {this.state.current ? (
          <div className='content-aanvraag'>
            <div className='container information-container'>
              <Information
                title={this.state.current.title}
                showNext
                fullScreen
                paragraph1={this.state.current.content}
                paragraph2={this.state.current.content2}
                paragraph3={this.state.current.content3}
                paragraph3Extra={
                  <div className='cta-icon button-bar container'>
                    <Link to='/app/uitslag'>
                      <div className='button'>
                        <img src={result} />
                      </div>
                      <h3 className='mobile-label'>Uitslag</h3>
                    </Link>
                  </div>
                }
              />
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    page: state.get('aanvraagPages')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestAanvraagPages: () => dispatch({ type: 'AANVRAAG_PAGES_REQUESTED' })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Aanvraag)
