import React from 'react'
import { connect } from 'react-redux'

import Header from 'components/header/Header'
import Footer from 'components/footer/Footer'
import Home from 'components/main/home/Home'

class App extends React.Component {
  componentWillMount () {
    this.props.requestAppPages()
    this.props.requestAanvraagPages()
    this.props.requestUitslagPages()
  }

  render () {
    return (
      <div className='App'>
        <Header />
        <Home />
        <Footer />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestAppPages: () =>
      dispatch({
        type: 'APP_PAGES_REQUESTED'
      }),
    requestUitslagPages: () =>
      dispatch({
        type: 'UITSLAG_PAGES_REQUESTED'
      }),
    requestAanvraagPages: () =>
      dispatch({
        type: 'AANVRAAG_PAGES_REQUESTED'
      })
  }
}

export default connect(null, mapDispatchToProps)(App)
