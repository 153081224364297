import React from 'react'
import { Link } from 'react-router-dom'

import Radboudumc from 'images/radboud-logo.svg'
import MUMC from 'images/mumc-logo.png'

import styles from './Footer.module.css'

class Footer extends React.Component {
  render () {
    return (
      <div className={`${styles.footer} d-md-block`}>
        <div className={styles.line} />
        <div className='container-fluid'>
          <div className='row justify-content-md-between align-items-md-center text-center'>
            <div className='col-md-5 text-md-left'>
              <Link
                to={'//www.radboudumc.nl/afdelingen/genetica'}
                target='_blank'
              >
                <img
                  src={Radboudumc}
                  className={styles.footerLogo}
                  width='120'
                />
              </Link>
              <Link to={'//klinischegenetica.mumc.nl/'} target='_blank'>
                <img src={MUMC} className={styles.footerLogo} width='120' />
              </Link>
            </div>
            <div className={styles.footerTextLinks}>
              <div className='col-md-auto'>
                <span>© {new Date().getFullYear()}</span>
              </div>
              <div className='col-md-5 text-md-right'>
                <Link
                  to={'/disclaimer'}
                  className={`font-weight-bold ${styles.disclaimerLink}`}
                >
                  Disclaimer
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
