import { combineReducers } from 'redux-immutable'
import pages from './app_pages/pages'
import isLoading from './app_pages/is_loading'
import error from './app_pages/error'

export default combineReducers({
  pages,
  isLoading,
  error
})
