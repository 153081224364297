import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'

import notBook from './images/notBook.png'
import ProgressNav from '../progressNav/ProgressNav'
import HeaderMain from '../header-main/HeaderMain'
import ModalExample from '../home/modal-meerinfo/meerInfoModal'
import signpost from 'images/signpost.svg'

import './Second.css.scss'

class Second extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      current: null,
      option1: null
    }
  }

  componentWillReceiveProps (nextProps) {
    this.props !== nextProps && this.getPage()
  }

  componentWillMount () {
    this.props.requestAppPages()
    this.getPage()
  }

  getPage () {
    let pages = this.props.appPages.toJS()
    pages = pages.pages
    let foundPagelink = _.find(pages, {
      url: 'criteria'
    })
    if (foundPagelink) {
      this.setState({
        current: foundPagelink
      })
    }
  }

  goToNextScreen (value) {
    var url = null

    if (value === 'Ja' || value === 'Anders') {
      if (this.props.firstQuestionAnswer === false) {
        url = '/app/third'
      } else {
        url = '/app/meerinformatie'
      }
    } else if (value === 'Nee') {
      url = '/app/geenspoed'
    }

    url && this.props.history.push(url)
  }

  render () {
    return (
      <div className='second-page'>
        <HeaderMain title={'Indicatie'} image={signpost} />
        <ProgressNav active={2} steps={3} />
        {this.state.current !== null ? (
          <React.Fragment>
            <div className='vraag container-fluid'>
              <h2>{this.state.current.title}</h2>
            </div>
            <div>
              <div
                className='center-container'
                dangerouslySetInnerHTML={{ __html: this.state.current.content }}
              />

              <div className='form container-fluid bottom-bar'>
                <form>
                  <input
                    type='radio'
                    className='vraag'
                    id='ja'
                    name='vragen2'
                    value='Ja'
                    onChange={e => this.goToNextScreen(e.target.value)}
                  />
                  <label htmlFor='ja'>Ja</label>
                  <input
                    type='radio'
                    className='vraag'
                    id='nee'
                    name='vragen2'
                    value='Nee'
                    onChange={e => this.goToNextScreen(e.target.value)}
                  />
                  <label htmlFor='nee'>Nee</label>
                </form>
                {this.state.current.more && (
                  <div className='moreinfo'>
                    <img src={notBook} />
                    <span className='modals'>
                      <ModalExample
                        title='1e en 2e graads familieleden'
                        content={this.state.current.more}
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    appPages: state.get('appPages'),
    firstQuestionAnswer: state.get('firstQuestionAnswer')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestAppPages: () =>
      dispatch({
        type: 'APP_PAGES_REQUESTED'
      })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Second))
