import React from 'react'

import share from 'images/share.svg'
import settings from 'images/chrome-settings.svg'
import appIcon from 'images/app-icon.png'
import close from 'images/close.png'

import styles from './InstallPopup.module.css'

class InstallPopup extends React.Component {
  render () {
    const isIos = () => {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    }

    return (
      <div className={styles.outerContainer}>
        <div className={styles.container}>
          <span
            className={styles.closeBtn}
            onClick={() => this.props.closePopup()}
          >
            <img className={styles.closeBtnImg} src={close} />
          </span>
          <h5>Installeer de app</h5>
          <div className={styles.popupContentContainer}>
            <span className={styles.content}>
              Druk op{' '}
              <img className={styles.share} src={isIos() ? share : settings} />
              en vervolgens op
              {isIos()
                ? ' "zet op het beginscherm" '
                : ' "toevoegen aan startscherm" '}
              om de informatie nog sneller te benaderen.
            </span>
            <img className={styles.appIcon} src={appIcon} />
          </div>
        </div>
      </div>
    )
  }
}

export default InstallPopup
