import React from 'react'
import { withRouter } from 'react-router-dom'

import './progressNav.scss'

class ProgressNav extends React.Component {
  openPage (url) {
    url && this.props.history.push(url)
  }

  createSteps () {
    const steps = this.props.steps
    let stepsView = []
    let stepClass = null
    for (let i = 1; i <= steps; i++) {
      let url = null
      if (this.props.active === i) {
        stepClass = 'active'
      } else {
        stepClass = null
      }

      if (i > this.props.active) {
        stepClass = 'transparent'
      } else if (this.props.warning && this.props.active === i) {
        stepClass = 'warning'
      }

      if (!stepClass) {
        console.log(i)
        i === 1 ? (url = '/app/indicatie') : (url = '/app/second')
        console.log(url)
      }

      stepsView.push(
        <li
          key={`${i}-progress`}
          onMouseEnter={() => console.log(`${i} ${url}`)}
          onClick={() => this.openPage(url)}
        >
          <div className={stepClass}>{i}</div>
        </li>
      )
    }

    return stepsView
  }

  render () {
    return (
      <div className='page-number-container'>
        <div className='page-number'>
          <ul>{this.createSteps()}</ul>
        </div>
      </div>
    )
  }
}

export default withRouter(ProgressNav)
