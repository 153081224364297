import React from 'react'
import { useTransition, animated } from 'react-spring'

export default function Question (Props) {
  const items = Props.title
  const transitions = useTransition(items, null, {
    from: {
      transform: `translate3d(${!Props.prev ? '-' : ''}400px,0,0)`,
      opacity: 0
    },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    leave: {
      transform: `translate3d(${Props.prev ? '-' : ''}400px,0,0)`,
      opacity: 0
    },
    native: true,
    order: ['leave', 'enter', 'update']
  })
  return transitions.map(
    ({ item, props }) =>
      item && <animated.h3 style={props}>{item.title}</animated.h3>
  )
}
