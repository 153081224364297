import React from 'react'
import Jumbotron from '../jumbotron/Jumbotron'
import Content from '../content/Content'

import './Home.scss'

class Home extends React.Component {
  componentWillReceiveProps (newProps) {
    if (this.props.slug !== newProps.slug) {
      console.log(newProps.slug)
    }
  }

  render () {
    return (
      <React.Fragment>
        {this.props.jumbtronDisable ? null : (
          <Jumbotron
            root={this.props.root}
            section={this.props.section}
            content={this.props.content}
            profPage={this.props.profPage}
          />
        )}
        <Content
          root={this.props.root}
          profPage={this.props.profPage}
          content={this.props.content}
          allPages={this.props.allPages}
        />
      </React.Fragment>
    )
  }
}

export default Home
