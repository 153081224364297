import React from 'react'
import { useTransition, animated } from 'react-spring'

import caretLeft from 'images/caret-left.svg'
import refresh from 'images/refresh.svg'

export default function BackResetButton (Props) {
  const items = Props.type
  const transitions = useTransition(items, null, {
    from: { marginTop: '-40px', position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    native: true,
    order: ['leave', 'enter', 'update']
  })
  return transitions.map(
    ({ item, props }) =>
      item && (
        <animated.div
          className='text-link-container-left'
          onClick={e => Props.onClick(e)}
          style={props}
        >
          {item === 'back' ? (
            <React.Fragment>
              <img className='nested-arrow' src={caretLeft} />
              <span>Stap terug</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <img className='nested-arrow' src={refresh} />
              <span>Begin opnieuw</span>
            </React.Fragment>
          )}
        </animated.div>
      )
  )
}
