import { combineReducers } from 'redux-immutable'

import page from './page'
import pages from './pages'
import appPages from './app_pages'
import uitslagPages from './uitslag_pages'
import aanvraagPages from './aanvraag_pages'
import firstQuestionAnswer from './first_question_answer'

export default combineReducers({
  page,
  pages,
  appPages,
  uitslagPages,
  aanvraagPages,
  firstQuestionAnswer
})
