import { List, fromJS } from 'immutable'

export default (state = List(), action) => {
  switch (action.type) {
    case 'PAGES_SUCCEEDED':
      return fromJS(action.page)
    default:
      return state
  }
}
