import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import Blueblob from '../patientinfo/Uitslagprocedure/Blueblob'
import book from 'images/book-blue.svg'
import patientBlue from 'images/patient-blue.svg'
import patient from 'images/patient.svg'

import './Content.scss'

class Content extends React.Component {
  transform (node, children) {
    if (node.tag === 'iframe') {
      return node
    }
  }

  render () {
    let pagelinksView = null
    let pagelinksArray = []
    let foundPagelink = null
    let breadcrumbPages = []
    let breadcrumbView = []
    let breadcrumbOuterView = null

    if (this.props.content instanceof Object) {
      if ('pagelinks' in this.props.content) {
        if (
          this.props.content.pagelinks.length > 0 &&
          !(
            this.props.content.pagelinks.length === 1 &&
            this.props.content.pagelinks.includes('')
          )
        ) {
          this.props.content.pagelinks.map((pagelink, i) => {
            if (pagelink !== '') {
              foundPagelink = null
              foundPagelink = _.find(this.props.allPages, {
                id: Number(pagelink)
              })
              if (foundPagelink && foundPagelink.live) {
                let foundPagelinkUrl = foundPagelink.url
                if (
                  (!foundPagelink.parent_id ||
                    foundPagelink.parent_id === this.props.root.id) &&
                  foundPagelink.children.length > 0
                ) {
                  foundPagelinkUrl = foundPagelink.children[0].url
                }
                pagelinksArray.push(
                  <div key={`${i}-pagelink`}>
                    <Link to={`/${this.props.root.url}/${foundPagelinkUrl}`}>
                      <img src={patient} />
                      <p
                        className={
                          this.props.content.id === foundPagelink.id
                            ? 'active'
                            : null
                        }
                      >
                        {foundPagelink.title}
                      </p>
                    </Link>
                  </div>
                )
              } else {
                this.props.allPages.map(rootPage => {
                  foundPagelink = _.find(rootPage.children, {
                    id: Number(pagelink)
                  })
                  if (foundPagelink && foundPagelink.live) {
                    pagelinksArray.push(
                      <div key={`${i}-pagelink`}>
                        <Link
                          to={`/${this.props.root.url}/${foundPagelink.url}`}
                        >
                          <img src={patient} />
                          <p
                            className={
                              this.props.content.id === foundPagelink.id
                                ? 'active'
                                : null
                            }
                          >
                            {foundPagelink.title}
                          </p>
                        </Link>
                      </div>
                    )
                  } else {
                    rootPage.children.map(childPage => {
                      foundPagelink = _.find(childPage.children, {
                        id: Number(pagelink)
                      })
                      if (foundPagelink) {
                        if (foundPagelink.live) {
                          pagelinksArray.push(
                            <div key={`${i}-pagelink`}>
                              <Link
                                to={`/${this.props.root.url}/${
                                  foundPagelink.url
                                }`}
                              >
                                <img src={patient} />
                                <p
                                  className={
                                    this.props.content.id === foundPagelink.id
                                      ? 'active'
                                      : null
                                  }
                                >
                                  {foundPagelink.title}
                                </p>
                              </Link>
                            </div>
                          )
                        }
                      } else {
                        childPage.children.map(childThirdPage => {
                          foundPagelink = _.find(childThirdPage.children, {
                            id: Number(pagelink)
                          })
                          if (foundPagelink) {
                            if (foundPagelink.live) {
                              pagelinksArray.push(
                                <div key={`${i}-pagelink`}>
                                  <Link
                                    to={`/${this.props.root.url}/${
                                      foundPagelink.url
                                    }`}
                                  >
                                    <img src={patient} />
                                    <p
                                      className={
                                        this.props.content.id ===
                                        foundPagelink.id
                                          ? 'active'
                                          : null
                                      }
                                    >
                                      {foundPagelink.title}
                                    </p>
                                  </Link>
                                </div>
                              )
                            }
                          }
                        })
                      }
                    })
                  }
                })
              }
            }
          })
        } else {
          let parentID = this.props.content.parent_id
          let parentThirdPage = null
          if (parentID) {
            foundPagelink = _.find(this.props.allPages, {
              id: Number(parentID)
            })
            if (foundPagelink && foundPagelink.live) {
              foundPagelink.children.map((page, i) => {
                if (page.live) {
                  pagelinksArray.push(
                    <div key={`${i}-rootpagelink`}>
                      <Link to={`/${this.props.root.url}/${page.url}`}>
                        <img src={patient} />
                        <p
                          className={
                            this.props.content.id === page.id ? 'active' : null
                          }
                        >
                          {page.title}
                        </p>
                      </Link>
                    </div>
                  )
                }
              })
            } else {
              this.props.allPages.map(rootPage => {
                let foundPagelinkInRoot = _.find(rootPage.children, {
                  id: Number(parentID)
                })
                if (foundPagelinkInRoot) {
                  foundPagelink = foundPagelinkInRoot
                } else {
                  rootPage.children.map(rootChildPage => {
                    foundPagelinkInRoot = _.find(rootChildPage.children, {
                      id: Number(parentID)
                    })
                    if (foundPagelinkInRoot) {
                      foundPagelink = foundPagelinkInRoot
                      parentThirdPage = _.find(rootPage.children, {
                        id: Number(foundPagelinkInRoot.parent_id)
                      })
                    }
                  })
                }
              })
              if (foundPagelink && foundPagelink.live) {
                if (parentThirdPage) {
                  if (parentThirdPage.live) {
                    pagelinksArray.push(
                      <div key={`${parentThirdPage.id}-parent-pagelink`}>
                        <Link
                          to={`/${this.props.root.url}/${parentThirdPage.url}`}
                        >
                          <img src={patient} />
                          <p
                            className={
                              this.props.content.id === parentThirdPage.id
                                ? 'active'
                                : null
                            }
                          >
                            {parentThirdPage.title}
                          </p>
                        </Link>
                      </div>
                    )
                  }
                }

                if (foundPagelink.live) {
                  pagelinksArray.push(
                    <div key={`${foundPagelink.id}-parent-pagelink`}>
                      <Link to={`/${this.props.root.url}/${foundPagelink.url}`}>
                        <img src={patient} />
                        <p
                          className={
                            this.props.content.id === foundPagelink.id
                              ? 'active'
                              : null
                          }
                        >
                          {foundPagelink.title}
                        </p>
                      </Link>
                    </div>
                  )
                }

                foundPagelink.children.map((page, i) => {
                  if (page.live) {
                    pagelinksArray.push(
                      <div key={`${i}-pagelink`}>
                        <Link to={`/${this.props.root.url}/${page.url}`}>
                          <img src={patient} />
                          <p
                            className={
                              this.props.content.id === page.id
                                ? 'active'
                                : null
                            }
                          >
                            {page.title}
                          </p>
                        </Link>
                      </div>
                    )
                  }
                })
              }
            }
          }

          pagelinksArray.unshift(
            <div key={`$rootpagelink`}>
              <Link to={`/${this.props.root.url}`}>
                <img src={patient} />
                <p>Home</p>
              </Link>
            </div>
          )
        }
        if (pagelinksArray.length > 0) {
          pagelinksView = (
            <div className='leesmeer'>
              <h4>Lees meer</h4>
              {pagelinksArray}
            </div>
          )
        }

        if (this.props.content.ancestry) {
          if (this.props.content.ancestry.indexOf('/')) {
            breadcrumbPages = this.props.content.ancestry.split('/')
          } else if (this.props.content.ancestry) {
            breadcrumbPages.push(this.props.content.ancestry)
          }
          // Filter out the root page
          breadcrumbPages = breadcrumbPages.filter(
            id => Number(id) !== 0 && Number(id) !== this.props.root.id
          )

          if (breadcrumbPages.length > 0) {
            // Add the root page
            breadcrumbView.push(
              <div className='breadcrumb-nested'>
                <Link to={`/${this.props.root.url}`}>
                  <p>{this.props.root.title}</p>
                </Link>
              </div>
            )

            breadcrumbPages.map(pageId => {
              foundPagelink = null
              foundPagelink = _.find(this.props.allPages, {
                id: Number(pageId)
              })
              if (foundPagelink && foundPagelink.children.length > 0) {
                breadcrumbView.push(
                  <div className='breadcrumb-nested'>
                    <p>></p>
                    <Link to={foundPagelink.children[0].url}>
                      <p>{foundPagelink.title}</p>
                    </Link>
                  </div>
                )
              } else if (foundPagelink) {
                breadcrumbView.push(
                  <div className='breadcrumb-nested'>
                    <Link to={foundPagelink.url}>
                      <p>Home</p>
                    </Link>
                  </div>
                )
              } else {
                this.props.allPages.map(rootPage => {
                  foundPagelink = _.find(rootPage.children, {
                    id: Number(pageId)
                  })
                  if (foundPagelink) {
                    breadcrumbView.push(
                      <div className='breadcrumb-nested'>
                        <p>></p>
                        <Link to={foundPagelink.url}>
                          <p>{foundPagelink.title}</p>
                        </Link>
                      </div>
                    )
                  } else {
                    rootPage.children.map(childPage => {
                      foundPagelink = _.find(childPage.children, {
                        id: Number(pageId)
                      })
                      if (foundPagelink) {
                        breadcrumbView.push(
                          <div className='breadcrumb-nested'>
                            <p>></p>
                            <Link to={foundPagelink.url}>
                              <p>{foundPagelink.title}</p>
                            </Link>
                          </div>
                        )
                      }
                    })
                  }
                })
              }
            })
            breadcrumbView.push(
              <div className='breadcrumb-nested'>
                <p>></p>
                <p className='active'>{this.props.content.title}</p>
              </div>
            )
            breadcrumbOuterView = (
              <div className='header-breadcrumb'>{breadcrumbView}</div>
            )
          }
        }
      }
    }

    let containerStyle = ''
    if (this.props.content) {
      if (!('url' in this.props.content)) {
        containerStyle = 'no-jumbotron'
      }
    }

    return (
      <div className='content'>
        <div className={`content-container ${containerStyle}`}>
          {this.props.content
            ? breadcrumbOuterView !== null ? breadcrumbOuterView : null
            : null}
          <div className='content-inner-container'>
            <div className='content-info'>
              <div className='content-header'>
                <img
                  className='header-icon'
                  src={this.props.profPage ? patientBlue : book}
                />
                <div className='header-titles'>
                  <h2>
                    {this.props.content ? (
                      `${this.props.content.title}`
                    ) : (
                      <Skeleton />
                    )}
                  </h2>
                </div>
              </div>
              {this.props.content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.content
                  }}
                />
              ) : (
                <Skeleton count={7} />
              )}
              {this.props.content ? (
                this.props.content.blueblob ? (
                  <Blueblob
                    profPage={this.props.profPage}
                    paragraph={this.props.content.blueblob}
                  />
                ) : null
              ) : (
                <Skeleton count={7} />
              )}
            </div>
            <div className='sidebar'>
              {this.props.content instanceof Object ? (
                'pdfs' in this.props.content ? (
                  this.props.content.pdfs instanceof Array ? (
                    this.props.content.pdfs.length > 0 ? (
                      <div className='pdfs'>
                        <h4>Bijlages</h4>
                        {this.props.content.pdfs.map((pdf, i) => (
                          <div key={`${i}-pdf`}>
                            <Link
                              to={`//${pdf.url.replace('https://', '')}`}
                              target='_blank'
                            >
                              <img src={patient} />
                              <p>{`${
                                pdf.url
                                  .split(/[/]+/)
                                  .pop()
                                  .split('?')[0]
                              }`}</p>
                            </Link>
                          </div>
                        ))}
                      </div>
                    ) : null
                  ) : null
                ) : null
              ) : null}
              {pagelinksView}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Content
