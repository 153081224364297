import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Transition } from 'react-spring/renderprops'

import './form.css.scss'

class Form extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      option1: null,
      option2: null
    }
  }

  changeFirstQuestion (e) {
    this.setState({ option1: e.target.value })
    let newAnswer = true
    if (e.target.value === 'Nee') {
      newAnswer = false
    }
    this.props.firstQuestion(newAnswer)
  }

  setSecondChoice (value) {
    this.setState({ option2: value })
    var url = null

    if (this.state.option1 === 'Ja' && value === 'Nee') {
      url = '/app/second'
    } else if (this.state.option1 === 'Nee' && value === 'Nee') {
      url = '/app/second'
    } else if (this.state.option1 === 'Nee' && value === 'Ja') {
      url = '/app/verwijzing'
    } else if (this.state.option1 === 'Ja' && value === 'Ja') {
      url = '/app/jongeleeftijd'
    }

    url && this.props.history.push(url)
  }

  render () {
    const { option1 } = this.state
    return (
      <div className='form'>
        <div className='ja-nee container'>
          <h2>Is bij patiënte recent mammacarcinoom vastgesteld?</h2>
          <form className='answer-container container'>
            <input
              type='radio'
              className='vraag'
              id='Ja'
              name='vragen1'
              value='Ja'
              onChange={e => this.changeFirstQuestion(e)}
            />
            <label htmlFor='Ja'>Ja</label>
            <input
              type='radio'
              className='vraag'
              id='Nee'
              name='vragen1'
              value='Nee'
              onChange={e => this.changeFirstQuestion(e)}
            />
            <label htmlFor='Nee'>Nee</label>
          </form>
          <Transition
            items={option1}
            from={{
              opacity: 0.3,
              cursor: 'default'
            }}
            enter={{ opacity: 1, cursor: 'pointer' }}
            leave={{ opacity: 0 }}
          >
            {option1 =>
              option1 &&
              (props => (
                <div style={props}>
                  <h2>Is patiënte jonger dan 31 jaar?</h2>
                  <form className='answer-container container'>
                    <input
                      type='radio'
                      className='vraag'
                      id='jonger'
                      name='vragen2'
                      value='Ja'
                      onChange={e => this.setSecondChoice(e.target.value)}
                    />
                    <label htmlFor='jonger'>Ja</label>
                    <input
                      type='radio'
                      className='vraag'
                      id='ouder'
                      name='vragen2'
                      value='Nee'
                      onChange={e => this.setSecondChoice(e.target.value)}
                    />
                    <label htmlFor='ouder'>Nee</label>
                  </form>
                </div>
              ))
            }
          </Transition>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    firstQuestionAnswer: state.get('firstQuestionAnswer')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    firstQuestion: answer =>
      dispatch({ type: 'SET_FIRST_QUESTION', answer: answer })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form))
