import React from 'react'

import './Tooltip.scss'

class Tooltip extends React.Component {
  render () {
    return <div className='tools'>{this.props.children}</div>
  }
}

export default Tooltip
