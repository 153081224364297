import React from 'react'
import { Transition } from 'react-spring/renderprops'

import Information from '../Information/Information'
import Choice from '../choice/Choice'
import Question from '../question/Question'
import BackResetButton from '../backResetButton/BackResetButton'

import styles from './MultipleChoice.module.css'

class MultipleChoice extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      current: this.props.pages,
      prev: this.props.pages,
      finalChoiceMade: false,
      prevAnimation: false
    }
  }

  // openPreviousChoices () {
  //     this.openNextChoices(findPrev)
  //   }
  // }

  openNextChoices (e, page, prev) {
    e.preventDefault()
    if (page.children.length > 0) {
      prev
        ? this.setState({
          current: [],
          prev: this.props.pages,
          prevAnimation: true
        })
        : this.setState({
          current: [],
          prev: this.state.current,
          prevAnimation: false
        })
      setTimeout(() => {
        this.setState({ current: page })
      }, 700)
    } else {
      if (prev) {
        this.setState({
          current: this.props.pages,
          prev: { children: [] },
          finalChoiceMade: false,
          prevAnimation: true
        })
      } else {
        this.setState({
          current: page,
          finalChoiceMade: true,
          prev: { children: [] },
          prevAnimation: true
        })
      }
    }
  }

  createChoices (pages) {
    const items = 'children' in pages ? pages.children : []
    const itemsTitle = pages
    return (
      <div className={`container ${styles.singleChoices}`}>
        <Question prev={this.state.prevAnimation} title={itemsTitle} />
        <Choice
          prev={this.state.prevAnimation}
          choices={items}
          openNextChoices={(e, item) => this.openNextChoices(e, item)}
        />
      </div>
    )
  }

  render () {
    const { finalChoiceMade, current, prev } = this.state
    return (
      <div className={styles.absoluteContainer}>
        {(current !== prev ||
          (current === this.props.pages && current !== prev)) && (
          <BackResetButton
            type={finalChoiceMade ? 'reset' : 'back'}
            onClick={e => this.openNextChoices(e, this.state.prev, true)}
          />
        )}
        <Transition
          items={finalChoiceMade}
          from={{
            position: 'absolute',
            transform: 'translate3d(-400px,0,0)',
            opacity: 0
          }}
          enter={{ transform: 'translate3d(0,0px,0)', opacity: 1 }}
          leave={{ transform: 'translate3d(400px,0,0)', opacity: 0 }}
        >
          {finalChoiceMade => props =>
            !finalChoiceMade ? (
              <div style={props}>{this.createChoices(this.state.current)}</div>
            ) : (
              <Information
                fullscreen
                style={props}
                title={this.state.current.title}
                subtitle={this.state.current.subtitle}
                paragraph1={this.state.current.content}
                paragraph2={this.state.current.content2}
                showNext={finalChoiceMade}
              />
            )}
        </Transition>
      </div>
    )
  }
}
export default MultipleChoice
