import React from 'react'
import { Link } from 'react-router-dom'

import close from 'images/close.png'
import backArrow from 'images/back-arrow.svg'

import './HeaderMain.css'

class HeaderMain extends React.Component {
  render () {
    return (
      <div>
        <div className='back-ground container-1 fixed-header'>
          <div className='header-main-container'>
            <div className='logo'>
              <div>
                <Link className='back-btn' to='/app'>
                  <img src={backArrow} />
                </Link>
              </div>
              <div className='name-page'>
                <img src={this.props.image} alt={this.props.title} />
                <h1>{this.props.title}</h1>
              </div>
            </div>
            <div className='sluiten d-none d-md-block'>
              <Link to='#' onClick={() => window.close()}>
                sluiten <img src={close} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderMain
